<template>
  <BodySimple>
    <template #header>
      <h2 class="text-lg font-bold">
        <img src="@/assets/images/icons8-castle-96.png" class="h-20 inline-block" />
        پنل مدیریت
      </h2>
    </template>
    <template #default>
      <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
        <router-link
          v-for="(item, index) in options"
          :key="index"
          :to="item.link"
          class="
            p-3
            bg-blueGray-100
            border-blueGray-200
            hover:bg-blueGray-200
            border
            rounded-md
            flex
            items-center
          "
        >
          <div class="text-lg font-bold w-full">{{ item.text }}</div>
          <img :src="item.image" class="mx-2 h-24" />
        </router-link>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
export default {
  components: { BodySimple },
  data() {
    return {
      options: [
        {
          image: require("@/assets/images/ai-robot.png"),
          text: "ربات‌ها",
          link: {
            name: "AdminBots",
          },
        },
        {
          image: require("@/assets/images/users.png"),
          text: "کاربران",
          link: {
            name: "AdminUsers",
          },
        },
        {
          image: require("@/assets/images/business-startup.png"),
          text: "تبلیغات",
          link: {
            name: "AdminAds",
          },
        },
        {
          image: require("@/assets/images/office-staff.png"),
          text: "گروه ها",
          link: {
            name: "AdminGroups",
          },
        },
        {
          image: require("@/assets/images/icons8-micro-96.png"),
          text: "کانال ها",
          link: {
            name: "AdminChannels",
          },
        },
        {
          image: require("@/assets/images/finance-growth.png"),
          text: "امور مالی",
          link: {
            name: "AdminIncome",
          },
        },
      ],
    };
  },
};
</script>
